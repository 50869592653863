//#region imports
import { ContextMenu, Form, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExportCollision from "../../../components/exportCollision/exportCollision";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import RecalculateCollision from "../../../components/recalculateCollision/recalculateCollision";
import TesReportViewer from "../../../components/reportViewer/ReportViewer";
import getTitle from "../../../components/title/getTitle";
import ValidationReport from "../../../components/validationReport/validationReport";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../constants/Permissions";
import reportSettings from "../../../constants/report-settings";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  CollisionApiUrl,
  FieldApiUrl,
  ReportApiUrl,
} from "../../../environment/routeSettings";
import { UpdateGeoCodeStatusDTO } from "../../../types/collision/dto/collisionDtos";
import {
  ApprovalStatus,
  CollisionAttachmentType,
  CollisionDataSourceType,
  CollisionMergeStatus,
  CollisionOriginalSourceType,
  CollisionTesStatus,
  GeoCodeStatus,
} from "../../../types/collision/enums/collisionEnums";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  GridSetting,
  INameId,
  LazyLoadingRequest,
  NameValue,
} from "../../../types/general/generalTypes";
import {
  LocationType,
  MapUpdateStatus,
} from "../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../types/infrastructure/infrastructureTypes";
import {
  ClientReport,
  ReportLocation,
  UiReportRequest,
} from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { Enum2Array, EnumFlag2Array } from "../../../utils/enumTools";
import { useScreenSize } from "../../../utils/media-query";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./collisions.scss";
//#endregion

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${CollisionApiUrl()}/api/Collisions/GetCollisions`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const Collisions = () => {
  //#region consts
  const { activeLoading } = useAuth();
  const [showMap, setShowMap] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showGeoCodeStatusUpdater, setShowGeoCodeStatusUpdater] =
    useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const [showValidationReport, setShowValidationReport] =
    useState<boolean>(false);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(
    new ClientReport()
  );
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const [geoCodeStatus, setGeoCodeStatus] = useState<any>();
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [lstTesStatus, setLstTesStatus] = useState<NameValue[]>([]);
  const [lstMergeStatus, setLstMergeStatus] = useState<NameValue[]>([]);
  const [lstCollisionDataSourceType, setLstCollisionDataSourceType] = useState<
    NameValue[]
  >([]);
  const [lstMapUpdateStatus, setLstMapUpdateStatus] = useState<NameValue[]>([]);
  const [lstApprovalStatus, setLstApprovalStatus] = useState<NameValue[]>([]);
  const [lstOriginalSourceType, setLstOriginalSourceType] = useState<
    NameValue[]
  >([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const dropDownOptions = { width: 215 };
  const { is2xLarge } = useScreenSize();
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%");
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  //#endregion consts

  //#region functions

  const title = getTitle("/collision/collisions", "");
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (is2xLarge) {
      setReportPopupHeight("55%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstLocationType(Enum2Array(LocationType));
        await getInitialDataFields();
        await getInitialDataReports();
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        setLstTesStatus(Enum2Array(CollisionTesStatus));
        setLstMergeStatus(Enum2Array(CollisionMergeStatus));
        setLstCollisionDataSourceType(Enum2Array(CollisionDataSourceType));
        setLstMapUpdateStatus(Enum2Array(MapUpdateStatus));
        setLstApprovalStatus(Enum2Array(ApprovalStatus));
        setLstOriginalSourceType(Enum2Array(CollisionOriginalSourceType));
        await getInfrastructureTesCodeValues();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToCollisionDetails = useCallback(() => {
    history("/collision/collisionDetails/AddNew");
  }, [history]);

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [FieldCategoryType.CollisionGeneral],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [reportLocations.Collision_Collisions],
      };

      var res: ClientReport[] = await TesPost(
        ReportApiUrl() + "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      var validationReport = new ClientReport();
      validationReport.id = "validationReport";
      validationReport.name = t("validationReport");
      var reportLoc = new ReportLocation();
      reportLoc.name = reportLocations.Collision_Collisions;
      validationReport.reportLocations = [reportLoc];
      res.push(validationReport);
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function onRowClicked(e: any) {
    if (e.data._id !== undefined) {
      const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/collision/collisionDetails/${e.data._id}`);
      }
    }
  }

  async function onOpenReport(d: any) {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    if ((gridData?.length ?? 0) > reportSettings.noMaxItems) {
      notify(t("selectCorrectNumberOfGrid"), "warning", 5000);
      return;
    }
    if (d !== null) {
      setSelectedReport(d.itemData);
    }
    if (d.itemData.id === "validationReport") {
      setShowValidationReport(true);
    } else {
      setShowReportModal(true);
      setLstSelectedIds(gridData!.map((x) => x) as string[]);
    }
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  function onChangeGeoCodeStatus(value: any) {
    setGeoCodeStatus(value);
  }

  async function UpdateGeoCodeStatus() {
    try {
      if (activeLoading) activeLoading(true);
      var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
      if (gridData?.length === 0) {
        notify(t("pleaseSelectSomeData"), "warning", 5000);
        return;
      }
      const postOb: UpdateGeoCodeStatusDTO = {
        collisionIds: gridData as string[],
        geoCodeStatus: geoCodeStatus,
      };
      const res = await TesPost(
        CollisionApiUrl() + "/api/Collisions/UpdateGeoCodeStatus",
        postOb,
        true
      );
      dataGridRef.current?.instance().deselectAll();
      dataGridRef.current?.instance().refresh();
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        onCloseGeoCodeStatus();
      } else {
        await RequestErrorHandling(res);
        onCloseGeoCodeStatus();
      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("errorInFetchReports") + ex, "error", 5000);
      onCloseGeoCodeStatus();
    }
  }

  function onCloseGeoCodeStatus() {
    setShowGeoCodeStatusUpdater(false);
    setGeoCodeStatus({});
  }

  async function onOpenShowDelete() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    setShowDeletePopup(true);
  }
  async function onCloseShowDelete() {
    dataGridRef.current?.instance().deselectAll();
    setShowDeletePopup(false);
  }

  async function onDeleteCollisions() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    await TesPost(
      CollisionApiUrl() +
        "/api/Collisions/DeleteCollisions/" +
        localStorage.getItem("selectedCustomerId"),
      gridData,
      true
    );
  }

  const attachmentTypeFilterData = [
    {
      text: t("none"),
      value: ["CollisionAttachmentType", "=", 0],
    },
    {
      text: t("xml"),
      value: ["CollisionAttachmentType", "=", 1],
    },
    {
      text: t("pdf"),
      value: ["CollisionAttachmentType", "=", 2],
    },
    {
      text: t("jpg"),
      value: ["CollisionAttachmentType", "=", 4],
    },
    {
      text: t("png"),
      value: ["CollisionAttachmentType", "=", 8],
    },
    {
      text: t("xml") + "," + t("pdf"),
      value: ["CollisionAttachmentType", "=", 3],
    },
    {
      text: t("xml") + "," + t("jpg"),
      value: ["CollisionAttachmentType", "=", 5],
    },
    {
      text: t("xml") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 9],
    },
    {
      text: t("pdf") + "," + t("jpg"),
      value: ["CollisionAttachmentType", "=", 6],
    },
    {
      text: t("pdf") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 10],
    },
    {
      text: t("jpg") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 12],
    },
    {
      text: t("xml") + "," + t("pdf") + "," + t("jpg"),
      value: ["CollisionAttachmentType", "=", 7],
    },
    {
      text: t("xml") + "," + t("pdf") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 11],
    },
    {
      text: t("xml") + "," + t("jpg") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 13],
    },
    {
      text: t("pdf") + "," + t("jpg") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 14],
    },
    {
      text: t("xml") + "," + t("pdf") + "," + t("jpg") + "," + t("png"),
      value: ["CollisionAttachmentType", "=", 15],
    },
  ];
  const headerLookup = [
    {
      name: t("none"), // Represents no attachments
      value: 0,
    },
    {
      name: t("xml"),
      value: 1,
    },
    {
      name: t("pdf"),
      value: 2,
    },
    {
      name: t("jpg"),
      value: 4,
    },
    {
      name: t("png"),
      value: 8,
    },
    {
      name: t("xml") + ", " + t("pdf"),
      value: 3,
    },
    {
      name: t("xml") + ", " + t("jpg"),
      value: 5,
    },
    {
      name: t("xml") + ", " + t("png"),
      value: 9,
    },
    {
      name: t("pdf") + ", " + t("jpg"),
      value: 6,
    },
    {
      name: t("pdf") + ", " + t("png"),
      value: 10,
    },
    {
      name: t("jpg") + ", " + t("png"),
      value: 12,
    },
    {
      name: t("xml") + ", " + t("pdf") + ", " + t("jpg"),
      value: 7,
    },
    {
      name: t("xml") + ", " + t("pdf") + ", " + t("png"),
      value: 11,
    },
    {
      name: t("xml") + ", " + t("jpg") + ", " + t("png"),
      value: 13,
    },
    {
      name: t("pdf") + ", " + t("jpg") + ", " + t("png"),
      value: 14,
    },
    {
      name: t("xml") + ", " + t("pdf") + ", " + t("jpg") + ", " + t("png"),
      value: 15,
    },
  ];

  //#endregion functions
  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_D,
        CollisionPermissions.Collision_V,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data._id,
                "/collision/collisionDetails/",
                selectedRowData.row.data.LocationType,
                selectedRowData.row.data.LocationId
              )
            }
          />
          <h2 className={"content-block"}>{t("collisions")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                    style={{ marginRight: 10 }}
                    hint={t("showMap")}
                  />
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.Collision}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t("report")}
                    items={SortObjectByPropName(
                      initDataReports?.filter((x: ClientReport) =>
                        [reportLocations.Collision_Collisions].some(
                          (a) =>
                            x.reportLocations?.map((x) => x.name).indexOf(a) >=
                            0
                        )
                      ),
                      "name"
                    )}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator(
                        SortObjectByPropName(
                          initDataReports?.filter((x: ClientReport) =>
                            [reportLocations.Collision_Collisions].some(
                              (a) =>
                                x.reportLocations
                                  ?.map((x) => x.name)
                                  .indexOf(a) >= 0
                            )
                          ),
                          "name"
                        )
                      ),
                    }}
                    displayExpr="name"
                    onItemClick={(d: any) => onOpenReport(d)}
                  />

                  <Permission
                    allowed={[
                      TMSPermissions.TMS_Admin,
                      CollisionPermissions.Collision_GeoCode,
                    ]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => setShowGeoCodeStatusUpdater(true)}
                      icon="fa-solid fa-location"
                      hint={t("geoCodeStatusUpdater")}
                    />
                  </Permission>

                  <Permission
                    allowed={[TMSPermissions.TMS_Admin]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => onOpenShowDelete()}
                      icon="fa-solid fa-trash"
                      hint={t("delete")}
                    />
                  </Permission>

                  {/* <Permission
                  allowed={[CollisionPermissions.Collision_E, CollisionPermissions.Collision_D]}
                  hasFeedBackElement={false}> */}
                  <RecalculateCollision datagridRef={dataGridRef} />
                  {/* </Permission> */}

                  <Permission
                    allowed={[TMSPermissions.TMS_Admin]}
                    hasFeedBackElement={false}
                  >
                    <ExportCollision dataGridRef={dataGridRef} />
                  </Permission>
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCollisionDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("collision")}
                    />
                  </Permission>
                </div>
              </div>
              {initDataFields && initDataFields.length > 0 && (
                <DataGrid
                  id="gridContainer"
                  key={"_id"}
                  ref={dataGridRef}
                  dataSource={dataSource}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  onContextMenuPreparing={(e) => {
                    setSelectedRowData(e);
                  }}
                  onExporting={OnExporting}
                  style={{ margin: "0 1rem" }}
                  columnAutoWidth={gridSetting.horizontalScrolling}
                  //onContentReady={e => { e.component.deselectAll() }}
                  //onFilterValueChange={e => { dataGridRef?.current?.instance().deselectAll() }}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.Collision.toString()}
                    savingTimeout={500}
                  />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="_id" caption={t("id")} visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ParentId"
                    caption={t("parentId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="GeoId"
                    allowSorting={true}
                    caption={t("geoId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="CloneTargetGeoId"
                    caption={t("cloneTargetGeoId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="xMLImportMessage"
                    allowSorting={true}
                    caption={t("conflictedFields")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationDescription"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    width={300}
                    caption={t("locationDescription")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationType"
                    allowHeaderFiltering={true}
                    caption={t("locationType")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(lstLocationType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MunicipalityId"
                    visible={
                      initDataTesCodeValues.municipalities?.length !== 0 &&
                      initDataTesCodeValues.municipalities !== undefined
                    }
                    allowHeaderFiltering={true}
                    caption={t("municipality")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues?.municipalities,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter
                      dataSource={initDataTesCodeValues?.municipalities?.map(
                        (x) => ({ text: x.name, value: x.id })
                      )}
                    >
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="JurisdictionId"
                    visible={
                      initDataTesCodeValues.jurisdictions?.length !== 0 &&
                      initDataTesCodeValues.jurisdictions !== undefined
                    }
                    allowHeaderFiltering={true}
                    caption={t("jurisdiction")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues?.jurisdictions,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter
                      dataSource={initDataTesCodeValues?.jurisdictions?.map(
                        (x) => ({ text: x.name, value: x.id })
                      )}
                    >
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Year"
                    dataType="number"
                    alignment="left"
                    sortOrder={"desc"}
                    allowHeaderFiltering={true}
                    caption={t("accidentYear")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SubmissionDT"
                    caption={t("submissionDT")}
                    dataType="datetime"
                    visible={false}
                    format={generalSetting?.dateTimeFormat}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="DataSourceType"
                    caption={t("dataSourceType")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstCollisionDataSourceType,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="GeoCodeStatus"
                    caption={t("geoCodeStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstGeoCodeStatus,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="CollisionTesStatus"
                    caption={t("collisionTesStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(lstTesStatus, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="CollisionAttachmentType"
                    visible={false}
                    caption={t("collisionAttachmentType")}
                  >
                    <HeaderFilter
                      dataSource={attachmentTypeFilterData}
                      width={350}
                    >
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={headerLookup}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="CollisionMergeStatus"
                    caption={t("collisionMergeStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(lstMergeStatus, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="MapUpdateStatus"
                    caption={t("mapUpdateStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstMapUpdateStatus,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="EditedSubmissionDT"
                    caption={t("lastEditDT")}
                    visible={false}
                    dataType="datetime"
                    format={generalSetting?.dateFormat}
                  ></Column>
                  <Column
                    dataField="lastEditedUserName"
                    caption={t("lastEditedUserName")}
                    visible={false}
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  {initDataFields.map((d) => {
                    if (d.fieldType === FieldType.List) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowFiltering={true}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <Search enabled />
                          <Lookup
                            dataSource={SortObjectByPropName(
                              d.fieldValues,
                              "name"
                            )}
                            valueExpr="id"
                            displayExpr="name"
                          />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.String) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="string"
                          alignment="left"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Number) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="number"
                          alignment="left"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Date) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="date"
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(e, d, generalSetting)
                          }
                          format={generalSetting?.dateFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Time) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          allowHeaderFiltering={false}
                          allowGrouping={false}
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(e, d, generalSetting)
                          }
                          format={generalSetting?.timeFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter visible={false}>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.DateTime) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(e, d, generalSetting)
                          }
                          format={generalSetting?.dateTimeFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Boolean) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="boolean"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowHeaderFiltering={false}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    }
                  })}
                  <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(
                        customerDivisions!,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="ApproveLevel"
                    caption={t("approveLevel")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ApprovalStatus"
                    caption={t("approvalStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstApprovalStatus,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="DivisionId"
                    caption={t("DivisionId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  {/* <Column
                    dataField="editSubmissionMapLocation.Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("editSubmissionLatitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                    >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="editSubmission.Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("editSubmissionLongitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column> */}
                  <Column
                    dataField="GeoCodeGroupId"
                    caption={t("geoCodeGroupId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationId"
                    caption={t("locationId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="OriginalSourcetype"
                    caption={t("originalSourceType")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstOriginalSourceType,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                </DataGrid>
              )}
            </div>
          </div>
          {/* validation report */}
          <ValidationReport
            collisionIds={lstSelectedIds}
            showValidationReport={showValidationReport}
            setShowValidationReport={setShowValidationReport}
          />

          {/*Grid Filter*/}
          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Collision}
          />

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Collision}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"auto"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>

          {/* Geo code status updater */}
          <Popup
            visible={showGeoCodeStatusUpdater}
            width={"30%"}
            height={"60%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("geoCodeStatusUpdater")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseGeoCodeStatus()}
          >
            <Form>
              <SimpleItem>
                <SelectBox
                  placeholder=""
                  label={t("geoCodeStatus")}
                  labelMode="floating"
                  onValueChange={(e) => onChangeGeoCodeStatus(e)}
                  items={lstGeoCodeStatus}
                  displayExpr="name"
                  valueExpr="value"
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginTop: 30 }}>
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => UpdateGeoCodeStatus()}
                  text={t("update")}
                />
              </div>
            </div>
          </Popup>

          {/* Delete Collisions */}
          <Popup
            visible={showDeletePopup}
            width={"30%"}
            height={"40%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("deleteConfirmation")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowDeletePopup(false)}
          >
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "20px" }}>
                <p
                  style={{ margin: "10px 0", fontSize: "16px", color: "#333" }}
                >
                  {t("areYouSureDeleteCollisions")}
                </p>
                <p
                  style={{ margin: "10px 0", fontSize: "14px", color: "#666" }}
                >
                  {t("thisActionCannotBeUndone")}
                </p>
              </div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseShowDelete()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onDeleteCollisions}
                  text={t("delete")}
                />
              </div>
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Collisions;
